import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"
import { Link as GatsbyLink } from "gatsby"

import { itunesLink, googlePlayLink } from "../../constants/links"

import itunesBadge from "../../images/itunes-badge.svg"
import androidBadge from "../../images/android-badge.svg"

import logoP18 from "../../images/p18.png"

import styles from "./footer.module.css"

export default function Footer() {
  const { t } = useTranslation()

  const FooterItems = t("footer.items")
  const RenderFooterItems = []

  for (const item of FooterItems) {
    RenderFooterItems.push(
      <li>
        <GatsbyLink to={item.url}>{item.text}</GatsbyLink>
      </li>
    )
  }

  const footerText = t("footer.text")

  const footerP18 = t("footer.p18")

  return (
    <footer className={styles.footer}>
      <Container>
        <div className="row">
          <div className="col-md-6">
            <p className={styles.footerText}>{footerText}</p>
            <ul className={`list-inline ${styles.footerNav}`}>
              {RenderFooterItems}
            </ul>
          </div>
          <div className="col-md-6">
            <div className={styles.footerDownload}>
              <ul className="list-inline">
                <li>
                  <a
                    href={itunesLink}
                    className={styles.footerDownloadButton}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={`${t("image.alt")} Iphone app on Itunes`}
                      src={itunesBadge}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={googlePlayLink}
                    className={styles.footerDownloadButton}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={`${t("image.alt")} Android app on Google Play`}
                      src={androidBadge}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <p>
              <a href={footerP18.url} target="_blank" rel="noreferrer">
                <img
                  src={logoP18}
                  alt={`${t("image.alt")} ${footerP18.title}`}
                  className={styles.p18}
                />
              </a>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
